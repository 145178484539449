import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`Excavations began in Areas G and H in 2016. The area is a vast sector including sections of two city blocks overlooking the main thoroughfare to the north and south respectively. In Area G excavations have been carried out in the front of the city block occupied by a building dating to the imperial period. These buildings were later crowded with small, dry-stacked walls, arranged in rough parallel lines and made of stones, architectonic fragments and pottery sherds, extending over the road surface of the Via Gabina. The excavation of some of these walls exposed the level on which they were built and helped us to date the construction of the walls to at least the 5th c. CE. `}</MDXTag>
<MDXTag name="p" components={components}>{`Other activity dating to this period is attested by the north-south road itself, to the east of the imperial building. Excavation has shown a sequence of road levels paved in basalt or consisting of glareate surfaces dating from the 3rd c. BCE to the 5th c. CE. A similar sequence of basalt and later glareate paving is also clear on the main thoroughfare, the Via Gabina. `}</MDXTag>
<MDXTag name="p" components={components}>{`In the Area H, south of the Via Gabina excavation unearthed a series of buildings with both imperial and republican phases. The spaces seem to be associated with a large, open public space to the south west, respecting the grid plan of the city. In room H2 we can follow a sequence from a post abandonment use as an industrial production space, a building of unclear function which was reorganized in the imperial period, and the original republican phase which included a tufo slab floor and ashlar walls, preserved in some places to four courses. The republican phase of this room also includes a long drain, built of worked tufo blocks laid end to end and runs diagonally into a deep rectangular shaft cut in the bedrock in the southeastern corner of the room, which ultimately connects to a subterranean cuniculus. `}</MDXTag>
<MDXTag name="p" components={components}>{`Additionally, the excavation was carried out several smaller, taberna-like rooms (H3, H4, H5) laid out along and accessed from the eastern side of the open public space. While these were also rearranged in the imperial period, some of the original tufo floor, ashlar masonry, and a possible drainage system remains in situ. To the east of these spaces is room H6 which seems to have originally been a long, open corridor delimited on both the east and west by a long ashlar wall. It appears to have been built up and divided in the imperial period and beneath these levels a complex hydraulic system has been brought to light.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/su_7067.2.jpg","alt":null,"title":"Area H Building with internal well "}}></MDXTag></MDXTag></MDXTag>

export const _frontmatter = {"templateKey":"content-pages","title":"Area G/H","key":"area-g-h","parentKey":"excavation-areas"};

  